import * as Klaro from 'klaro/dist/klaro-no-css'

const config = {
  lang: 'de',
  styling: {
    theme: ['light'], // other additional options: 'top', 'wide'
  },
  translations: {
    en: {
      privacyPolicyUrl: 'https://tbd.com',
      googleAnalytics: {
        title: "Google Analytics",
        description: "Web analytics service offered by Google that tracks and reports website traffic.",
      },
      purposes: {
        analytics: "Analytics",
      }
    },
    de: {
      privacyPolicyUrl: 'https://tbd.com',
      googleAnalytics: {
        title: "Google Analytics",
        description: "Trackingtool von Google, das der Datenverkehrsanalyse von Webseiten dient.",
      },
      purposes: {
        analytics: "Datenverkehrsanalyse",
      }
    }
  },
  services: [
    {
      // integration with GTM: https://www.powerflash.at/blog/consent-tool-klaro-google-tagmanager/
      name: "googleAnalytics",
      title: "Google Analytics",
      purposes: ["analytics"],
      cookies: [
        /^_ga(_.*)?/ // we delete the Google Analytics cookies if the user declines its use
      ],
      callback: function (state, app) {
        if (state) {
          dataLayer.push({ event: 'consent-gtm-ga' })
        }
      }
    }
  ],
}


// we assign the Klaro module to the window, so that we can access it in JS
window.klaro = Klaro;
window.klaroConfig = config;

// we set up Klaro with the config
Klaro.setup(config);