document.addEventListener('DOMContentLoaded', () => {
    initForms()
    initProfileForm()
})

function initForms() {
    const inputs = document.querySelectorAll('.fui-field')
    for (let input of inputs) {
        if (input.id.indexOf('ollmacht') >= 0) {
            if (!input.classList.contains('Vollmacht')) {
                input.classList.add('Vollmacht')
            }
        }
        const inputElement = input.querySelector('input')
        if (inputElement) {
            const aboNumberFieldNames = [ 'fields[aboNummer]', 'fields[dacaSuntetiAbonatMentionatiNrIdBeiAbonniertenBi]' ]
            if (aboNumberFieldNames.includes(inputElement.name)
                && inputElement.value !== ''
            ) {
                inputElement.setAttribute('readonly', true)
            }
        }
    }
}

function initProfileForm() {
    const profileForm = document.querySelector('.profile-form')
    if (!profileForm) { return }

    const { showGroup } = profileForm.dataset
    const showClass = `group-${showGroup}`
    const fields = profileForm.querySelectorAll('.fui-field')
    for (let field of fields) {
        if (!field.classList.contains(showClass)) {
            field.classList.add('group-hidden')
        }
    }
}