document.addEventListener('DOMContentLoaded', () => {
    initProfile()
})

function initProfile() {
    const select = document.querySelector('.abo-select')
    if (!select) { return }
    select.addEventListener('change', onAboChanged)
    onAboChanged({ target: select })
}

function onAboChanged(event) {
    const { value } = event.target
    showHideDescription(value)
}

function showHideDescription(value) {
    const descEls = document.querySelectorAll('.abo-descriptions > div')
    for (let descEl of descEls) {
        const { abo } = descEl.dataset
        showElement(descEl, abo === value)
    }
}

function showElement(el, show) {
    el.style.display = show ? 'block' : 'none'
}
